import React from 'react'
import './footer.css'

function Footer() {
  return (
    <footer className='footer'>
        CopyRight 2024; BeEtEcH. All Rights Reserved.
    </footer>
  );
}

export default Footer